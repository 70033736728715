<template>
	<div class="publish_inquiry">
		<div class="goods_area">
			<div class="left_img">
				<img :src="$img(goods_detail.sku_image)" alt="">
			</div>
			<div class="goods_info">
				<div class="goods_name">{{goods_detail.sku_name}}</div>
				<div class="more_info">
					<div class="item">品牌：<span>{{goods_detail.brand_name || '暂无品牌'}}</span></div>
					<div class="item">评价：<span>{{goods_detail.evaluate}}</span></div>
					<div class="item">销量：<span>{{goods_detail.sale_num}}</span></div>
				</div>
				<div class="goods_price">价格：<span>{{goods_detail.discount_price}}</span></div>
				<div class="stock">库存：<span>{{goods_detail.stock}}</span></div>
				<div class="stock" v-if="goods_detail.delivery_date_name">交期：<span>{{ goods_detail.delivery_date_name }}</span></div>
				<div class="location">介绍：<span>{{goods_detail.introduction || '暂无介绍'}}</span></div>
			</div>
		</div>
		<div class="company">
			<div class="tit">所属公司</div>
			<div class="content">
				<div class="name">
					<img :src="$img('/upload/web/company_name.png')" />{{shop_info.site_name}}
				</div>
				<div class="company_info">
					<div class="item">联系人：<span>{{shop_info.name}}</span></div>
					<div class="item">联系方式：<span>{{shop_info.mobile}}</span></div>
					<div class="item">地区：<span>{{shop_info.full_address}}</span></div>
				</div>
			</div>
		</div>
		<div class="inquiry_info">
			<div class="tit">填写询价单内容</div>
			<div v-loading="loading">
				<div class="content">
					<div class="item">
						<div class="item_left"><span>*</span>求购量：</div>
						<input type="number" placeholder="请输入您的求购量" v-model="num">{{goods_detail.unit}}
					</div>
				</div>
				<div class="content" v-for="(item,index) in inquiry_from" :key="index">
					<div class="item" v-if="item.controller == 'Text'">
						<div class="item_left"><span>{{ item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-input class="dan-input" v-model="item.val" :placeholder="item.value.placeholder"></el-input>
					<!-- :disabled="data.length" -->
					</div>
					<div class="item" v-if="item.controller == 'Textarea'">
						<div class="item_left"><span>{{ item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-input class="duo-input"  type="textarea" :placeholder="item.value.placeholder" v-model="item.val">
						</el-input>
					</div>
					<div class="item" v-if="item.controller == 'Select'">
						<div class="item_left"><span>{{ item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-select v-model="item.val" :placeholder="item.value.placeholder" @change="chooseValue($event,index)">
						  <el-option v-for="(v,k) in item.value.options"  :key="v.index" :label="v" :value="v" >
						  </el-option>
						</el-select>
					</div>
					<div class="item" v-if="item.controller == 'Checkbox'">
						<div class="item_left"><span>{{ item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<!-- <el-checkbox v-for="(v,k) in item.option_lists" v-model="v.checked">{{v.value}}{{v.checked}}</el-checkbox> -->
						<el-checkbox-group v-model="item.show_val">
						  <el-checkbox v-for="(v,k) in item.value.options" :label="v"></el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="item" v-if="item.controller == 'Radio'">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-radio-group v-model="item.val">
						  <el-radio v-for="(v,k) in item.value.options" :label="v">{{v}}</el-radio>
						</el-radio-group>
					</div>
					<div class="item" v-if="item.controller == 'Img' ">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<!-- <div class="img-item">
							<img :src="$img(imgItem)" alt="" v-for="(imgItem,imgIndex) in item.val.split(',')" :key="imgIndex">
						</div> -->
						<div class="item_right" >
						  <el-upload ref="upload" :action="uploadActionUrl" :limit="item.value.max_count"
						   list-type="picture-card" :on-success="
								(file, fileList) => {
									return handleSuccess(file, fileList, index);
								}
							" :on-preview="handlePictureCardPreview" :on-remove="
								(file, fileList) => {
									return handleRemove(file, fileList, index);
								}
							" :on-exceed="(file,fileList)=>{
								return handleExceed(file,fileList,item.value.max_count);
							}">
							<i class="el-icon-plus"></i>
						  </el-upload>
						  <el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
						</div>
					</div>
					<div class="item" v-if="item.controller=='Date'">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-date-picker v-model="item.val" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</div>
					<div class="item" v-if="item.controller == 'Datelimit'">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-date-picker @change="changeDateLimit($event,item)" v-model="item.show_val" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div>
					<div class="item" v-if="item.controller == 'Time'">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-time-picker v-model="item.val" value-format="HH:mm" format="HH:mm" placeholder="任意时间点">
						</el-time-picker>
					</div>
					<div class="item" v-if="item.controller == 'Timelimit'">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
						<el-time-picker @change="changeTimeLimit($event,item)" is-range v-model="item.show_val" value-format="HH:mm" format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
					</div>
					<div class="item city" v-if="item.controller == 'City' ">
						<div class="item_left"><span>{{item.value.required ? '*' : '' }}</span>{{item.value.title}}：</div>
							<el-select v-model="formData.province_id" placeholder="请选择省" @change="changeProvice(formData.province_id)">
							  <el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">
								{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.city_id" placeholder="请选择市" @change="changeCity(formData.city_id)">
							  <el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">
								{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.district_id" placeholder="请选择区/县"
							  @change="changeDistrict(formData.district_id)">
							  <el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">
								{{ option.name }}</el-option>
							</el-select>
					</div>
				</div>
			</div>
			<div class="commit">
				<div class="btn" @click="toPublishInquiry()">立即发布</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { goodsSkuDetail, inquiryForm, addInquiry } from "@/api/goods/goods"
	import Config from '@/utils/config';
	import { getArea } from "@/api/address";
	export default {
		data() {
			return {
				num:'',//需求量
				end_time:'',
				sku_id:'',
				goods_detail:{},
				shop_info:{},
				inquiry_from:[],
				uploadActionUrl: Config.baseUrl + '/api/upload/evaluateimg',
				dialogVisible: false,
				dialogImageUrl: '',
				loading:true,
				formData: {
				  province_id: "",
				  city_id: "",
				  district_id: "",
				},
				province: [],
				city: [],
				district: [],
				defaultRegions: [],
				pickerValueArray: [],
			}
		},
		created() {
			this.sku_id = this.$route.query.sku_id || "" ;
			this.getGoodsDetail();
			this.getDefaultAreas(0, {
			  level: 0
			})
		},
		computed: {
		  pickedArr() {
		    // 进行初始化
		    if (this.isInitMultiArray) {
		      return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
		    }
		    return [this.pickerValueArray[0], this.city, this.district]
		  }
		},
		methods: {
			toPublishInquiry(){
				this.inquiry_from.forEach(item=>{
					if(item.controller == 'Checkbox'){
						item.val = item.show_val.toString();
					}
				})
				if(this.verify()){
					var form_data = {
						"form_id":this.goods_detail.form_id,
						"form_data": this.inquiry_from 
					}
					addInquiry({
						sku_id:this.sku_id,
						num:this.num,
						form_data: JSON.stringify(form_data)
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'添加成功'});
							this.$router.push('/member/my_inquiry');
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
					})
				}
			},
			verify() {
				let verify = true;
				if(!this.num){
					this.$message.error('请输入求购量');
					verify = false;
					return;
				}
				if(this.num<0){
					this.$message.error('求购量最小为1件');
					verify = false;
					return;
				}
				for (let i = 0; i < this.inquiry_from.length; i++) {
					let item = this.inquiry_from[i];
					if (item.controller == 'Text') {
						if (item.value.required && !item.val) {
							verify = false;
							this.$message.error('请输入'+ item.value.title);
							break;
						}
			
						let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
						if (item.name == 'ID_CARD' && reg.test(item.val) === false) {
							verify = !item.value.required;
							if (verify == false) {
			
			          this.$message.error('身份证输入不合法');
								return false;
							}
							if (verify == true && item.val != '') {
			
			          this.$message.error('身份证输入不合法');
								return false;
							}
						}
					}
					
					if (item.controller == 'Textarea') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请输入' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Select') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Checkbox') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请至少选择一个' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Img') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请至少上传一张' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Date') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Datelimit') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
			
						if (this.$util.timeTurnTimeStamp(item.start_val) > this.$util.timeTurnTimeStamp(item.val)) {
							verify = false;
			        this.$message.error('结束日期不能小于开始日期');
							break;
						}
					}
			
					if (item.controller == 'Time') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
					}
			
					if (item.controller == 'Timelimit') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
			
						if (item.start_val >= item.end_val) {
							verify = false;
			
			        this.$message.error('结束时间必须大于开始时间');
							break;
						}
					}
			
					if (item.controller == 'City') {
						if (item.value.required && !item.val) {
							verify = false;
			
			        this.$message.error('请选择' + item.value.title);
							break;
						}
					}
					if(item.name == 'MOBILE'){
						var phonereg = /^1[3-9]\d{9}$/;
						if(!phonereg.test(item.val)){
							verify = false;
							this.$message.error('手机号格式不正确');
							return;
						}
					}
				}
			
				if (verify) {
					return this.inquiry_from;
				} else return verify;
			},
			changeTimeLimit(e, item) {
			  item.start_val = e[0]
			  item.end_val = e[1]
			  item.val = e[0]+' - '+e[1]
			},
			changeDateLimit(e, item) {
			  item.start_val = e[0]
			  item.end_val = e[1]
			  item.val = e[0]+' - '+ e[1]
			},
			handleRemove(file, fileList, index) {
				var item = this.inquiry_from[index];
				var imgs = item.val;
				var img_arr = [];
				if(imgs){
					img_arr = imgs.split(',');
				}
				img_arr.splice( img_arr.indexOf(file.response.data.pic_path) );
				this.$set(item,'val',img_arr.toString())
				this.$forceUpdate()
			},
			handleSuccess(file, fileList, index) {
				var item = this.inquiry_from[index];
				var imgs = item.val;
				var img_arr = [];
				if(imgs){
					img_arr = imgs.split(',');
				}
				img_arr = img_arr.concat(file.data.pic_path);
				this.$set(item,'val',img_arr.toString())
				this.$forceUpdate()
			},
			chooseValue(e,index){
				this.inquiry_from[index].val = e ;
			},
			changeProvice(id) {
			  this.getAreas(id, data => (this.city = data))
			  let obj = {}
			  obj = this.province.find(item => {
			    //这里的province就是上面遍历的数据源
			    return item.id === id //筛选出匹配数据
			  })
			  this.formData.city_id = ""
			  this.formData.district_id = ""
			  this.formData.full_address = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changeCity(id) {
			  this.getAreas(id, data => (this.district = data))
			  let obj = {}
			  obj = this.city.find(item => {
			    //这里的province就是上面遍历的数据源
			    return item.id === id //筛选出匹配数据
			  })
			  this.formData.district_id = ""
			  this.formData.full_address = this.formData.full_address + "-" + obj.name
			},
			/**
			 * 改变区
			 */
			changeDistrict(id) {
			  let obj = {}
			  obj = this.district.find(item => {
			    //这里的province就是上面遍历的数据源
			    return item.id === id //筛选出匹配数据
			  })
			  this.formData.full_address = this.formData.full_address + "-" + obj.name
			},
			// 异步获取地区
			getAreas(pid, callback) {
			  getArea({
			      pid: pid
			    })
			    .then(res => {
			      if (res.code == 0) {
			        var data = []
			        res.data.forEach((item, index) => {
			          data.push(item)
			        })
			        if (callback) callback(data)
			      }
			    })
			    .catch(err => {})
			},
			/**
			 * 获取省市区列表
			 */
			getDefaultAreas(pid, obj) {
			  getArea({
			      pid: pid
			    })
			    .then(res => {
			      if (res.code == 0) {
			        var data = []
			        var selected = undefined
			        res.data.forEach((item, index) => {
			          if (obj != undefined) {
			            if (obj.level == 0 && obj.province_id != undefined) {
			              selected = obj.province_id
			            } else if (obj.level == 1 && obj.city_id != undefined) {
			              selected = obj.city_id
			            } else if (obj.level == 2 && obj.district_id != undefined) {
			              selected = obj.district_id
			            }
			          }
			
			          if (selected == undefined && index == 0) {
			            selected = item.id
			          }
			          data.push(item)
			        })
			
			        this.pickerValueArray[obj.level] = data
			        if (obj.level + 1 < 3) {
			          obj.level++
			          this.getDefaultAreas(selected, obj)
			        } else {
			          this.isInitMultiArray = true
			          this.isLoadDefaultAreas = true
			        }
			
			        this.province = this.pickerValueArray[0]
			      }
			      this.loading = false
			    })
			    .catch(err => {
			      this.loading = false
			    })
			},
			/**
			 * 渲染默认值
			 */
			handleValueChange(e) {
			  // 结构赋值
			  let [index0, index1, index2] = e.detail.value
			  let [arr0, arr1, arr2] = this.pickedArr
			  let address = [arr0[index0], arr1[index1], arr2[index2]]
			
			  this.formData.full_address = ""
			  for (let i = 0; i < address.length; i++) {
			    if (this.formData.full_address) {
			      this.formData.full_address = this.formData.full_address + "-" + address[i].name
			    } else {
			      this.formData.full_address = this.formData.full_address + address[i].name
			    }
			  }
			},
			handleDefaultRegions() {
			  var time = setInterval(() => {
			    if (!this.isLoadDefaultAreas) return
			    this.isInitMultiArray = false
			
			    for (let i = 0; i < this.defaultRegions.length; i++) {
			      for (let j = 0; j < this.pickerValueArray[i].length; j++) {
			        this.province = this.pickerValueArray[0]
			
			        // 匹配省
			        if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
			          // 设置选中省
			          this.$set(this.multiIndex, i, j)
			          // 查询市
			          this.getAreas(this.pickerValueArray[i][j].id, data => {
			            this.city = data
			
			            for (let k = 0; k < this.city.length; k++) {
			              if (this.defaultRegions[1] == this.city[k].id) {
			                // 设置选中市
			                this.$set(this.multiIndex, 1, k)
			
			                // 查询区县
			                this.getAreas(this.city[k].id, data => {
			                  this.district = data
			
			                  // 设置选中区县
			                  for (let u = 0; u < this.district.length; u++) {
			                    if (this.defaultRegions[2] == this.district[u].id) {
			                      this.$set(this.multiIndex, 2, u)
			                      this.handleValueChange({
			                        detail: {
			                          value: [j, k, u]
			                        }
			                      })
			                      break
			                    }
			                  }
			                })
			                break
			              }
			            }
			          })
			        }
			      }
			    }
			    if (this.isLoadDefaultAreas) clearInterval(time)
			  }, 100)
			},
			
			handleExceed(file, fileList,max_count) {
			  // 图片数量大于6
			  this.$message.error('上传图片最大数量为'+max_count+'张');
			},
			handlePictureCardPreview(file) {
			  // 点开大图
			  this.dialogImageUrl = file.url;
			  this.dialogVisible = true;
			},
			getGoodsDetail() {
				goodsSkuDetail({
					sku_id:this.sku_id
				})
				.then(res=>{
					if(res.code>=0){
						this.goods_detail = res.data.goods_sku_detail;
						this.shop_info = res.data.shop_info;
						this.getInquiryForm(res.data.goods_sku_detail.goods_id);
					}
				})
			},
			getInquiryForm(id){
				inquiryForm({
					goods_id:id
				})
				.then(res=>{
					if(res.code>=0){
						this.inquiry_from = res.data;
						this.inquiry_from.forEach((item,index)=>{
							// item.val = '';
							this.$set(item,'val','');
							if(item.controller == 'Checkbox'){
								this.$set(item,'val','');
								this.$set(item,'show_val',[]);
							}
							if(item.controller == 'Datelimit' || item.controller == 'Timelimit' ){
								this.$set(item,'show_val','')
							}
						})
						console.log(this.inquiry_from,45);
						this.loading = false;
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	body{
		background-color: #F8F8F8 !important;
	}
	.date_picker{
		.el-input__inner{
			border: 1px solid #DDD;
			border-radius: 0;
			color: #555;
			font-size: 14px;
			line-height: 20px;
		}
		::-webkit-input-placeholder{
			color: #555 !important;
			font-size: 14px;
			line-height: 20px;
		}
	}
	.content{
		input::-webkit-input-placeholder{
			color: #555;
		}
	}
</style>
<style lang="scss">
	.publish_inquiry{
		width: 1200px;
		margin: 0 auto 30px;
		padding-top: 20px;
		.goods_area{
			display: flex;
			align-items: center;
			padding: 20px;
			background-color: #fff;
			margin-bottom: 12px;
			.left_img{
				width: 180px;
				height: 180px;
				margin-right: 24px;
				img{
					width: 100%;
					height: 100%;
					display: block;
				}
			}
			
			.goods_info{
				flex: 1;
				.goods_name{
					color: #222;
					font-size: 22px;
					line-height: 28px;
					margin-bottom: 8px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.more_info{
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					.item{
						margin-right: 80px;
						display: flex;
						align-items: center;
						color: #888;
						font-size: 14px;
						line-height: 20px;
						span{
							color: #222;
							margin-left: 14px;
						}
					}
				}
				.goods_price{
					margin-bottom: 5px;
					color: #888;
					font-size: 16px;
					line-height: 30px;
					span{
						color: #FA6400;
						font-size: 22px;
						line-height: 30px;
					}
				}
				.stock,.location{
					margin-bottom: 5px;
					color: #888;
					font-size: 16px;
					line-height: 24px;
					span{
						margin-left: 12px;
						color: #222;
						font-size: 16px;
						line-height: 22px;
					}
				}
				.location{
					margin-bottom: 0;
				}
			}
		}
		.company{
			padding: 25px 20px 25px;
			background-color: #fff;
			margin-bottom: 12px;
			.tit{
				font-weight: 600;
				color: #666;
				font-size: 18px;
				line-height: 22px;
				padding-left: 11px;
				border-left: 4px solid #0DAD94;
				margin-bottom: 15px;
			}
			.content{
				padding-left: 38px;
				.name{
					display: flex;
					align-items: center;
					color: #222;
					font-size: 17px;
					line-height: 24px;
					margin-bottom: 14px;
					img{
						margin-right: 8px;
						display: block;
						width: 20px;
						height: 20px;
					}
				}
				.company_info{
					display: flex;
					align-items: center;
					.item{
						color: #888;
						font-size: 16px;
						line-height: 22px;
						margin-right: 120px;
						&:last-child{
							margin-right: 0;
						}
						span{
							margin-left: 16px;
							color: #222;
						}
					}
				}
			}
		}
		.inquiry_info{
			padding: 25px 20px 25px;
			background-color: #fff;
			.tit{
				font-weight: 600;
				color: #666;
				font-size: 18px;
				line-height: 22px;
				padding-left: 11px;
				border-left: 4px solid #0DAD94;
				margin-bottom: 15px;
			}
			.content{
				padding-left: 37px;
				margin-bottom: 20px;
				.item{
					display: flex;
					align-items: center;
					&:last-child{
						margin-bottom: 0;
					}
					input::placeholder{
						color: #888;
					}
					.item_left{
						color: #333;
						font-size: 16px;
						min-width: 125px;
						white-space: nowrap;
						text-align: left;
						line-height: 22px;
						margin-right: 12px;
						span{
							color: #E02020;
							margin-right: 5px;
						}
					}
					.el-select,
					.el-date-editor{
						min-width: 350px;
					}
					
					&.text_area{
						align-items: baseline;
					}
					textarea{
						width: 350px;
						height: 96px;
						border: 1px solid #DDD;
						outline: none;
						padding: 9px;
						color: #555;
						font-size: 14px;
						font-family: auto;
						line-height: 20px;
					}
					>input{
						box-sizing: border-box;
						min-width: 350px;
						outline: none;
						padding: 9px 12px;
						border: 1px solid #DDD;
						color: #555;
						font-size: 14px;
						line-height: 20px;
						margin-right: 3px;
					}
					.dan-input{
						min-width: 350px;
						width: auto ;
						.el-input__inner{
							outline: none;
							padding: 9px 12px;
							border: 1px solid #DDD;
							border-radius: 0 !important;
							color: #555;
							font-size: 14px;
							line-height: 20px;
						}
					}
					.el-textarea__inner{
						border-radius: 0;
						border: 1px solid #DDD;
					}
					
				}
			}
			.commit{
				display: flex;
				align-items: center;
				justify-content: center;
				.btn{
					color: #fff;
					font-size: 19px;
					line-height: 26px;
					padding: 9px 62px;
					background-color: #0DAD94;
					border-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}
</style>